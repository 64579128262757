import Vue from 'vue'
import Vuex from 'vuex'

// Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeArticle: Object,
    activeArticleSearch: Object,
    selectedArticles: [],
    selectedArticlesSearch: [],
    loading: false,
    errored: false,
    activeBreadCrumb: '',
    activeBrand: '',
    activeBrandName: '',
    activeBrandId: '',
    activeBrandUrl: '',
    activeIssue: '',
    activeIssueUrl: '',
    store_xml_articleName: '',
    store_xml_summary: '',
    store_xml_publicationName: '',
    store_xml_byline: '',
    store_xml_text: ''
  },
  mutations: {
    setActiveArticle (state, article) {
      // console.log('selecting article mutation, ' + article)
      state.activeArticle = article
    },
    setActiveArticleSearch (state, article) {
      // console.log('selecting article mutation, ' + article)
      state.activeArticleSearch = article
    },
    setSelectedArticles (state, articles) {
      // console.log('selecting articles mutation, ' + articles)
      state.selectedArticles = articles
    },
    addSelectedArticle (state, article) {
      // console.log('adding article mutation, ' + article)
      state.selectedArticles.push(article)
      // console.log(state.selectedArticles.length)
    },
    removeSelectedArticle (state, article) {
      // console.log('removing article mutation ' + article)
      state.selectedArticles.splice(state.selectedArticles.indexOf(article), 1)
      // console.log(state.selectedArticles.length)
    },
    setSelectedArticlesSearch (state, articles) {
      // console.log('selecting articles search mutation, ' + articles)
      state.selectedArticlesSearch = articles
    },
    addSelectedArticleSearch (state, article) {
      // console.log('adding article search mutation, ' + article)
      state.selectedArticlesSearch.push(article)
      // console.log(state.selectedArticlesSearch.length)
    },
    removeSelectedArticleSearch (state, article) {
      // console.log('removing article Search mutation ' + article)
      state.selectedArticlesSearch.splice(state.selectedArticlesSearch.indexOf(article), 1)
      // console.log(state.selectedArticlesSearch.length)
    },
    setSearchBreadcrumbs (state) {
      // console.log('Setting search breadcrumbs mutation')
      state.activeBreadCrumb = 'Search'
      state.activeBrand = ''
      state.activeBrandUrl = ''
      state.activeIssue = ''
      state.activeIssueUrl = ''
    },
    setHomeBreadcrumbs (state) {
      // console.log('Setting home breadcrumbs mutation')
      state.activeBreadCrumb = ''
      state.activeBrand = ''
      state.activeBrandName = ''
      state.activeBrandId = ''
      state.activeBrandUrl = ''
      state.activeIssue = ''
      state.activeIssueUrl = ''
    },
    setBrandBreadcrumbs (state, { brandId, brandName }) {
      // console.log('Setting  brand breadcrumbs mutation ' + brandId + ' | ' + brandName)
      state.activeBrand = brandName
      state.activeBrandName = brandName
      state.activeBrandId = brandId
      state.activeBrandUrl = '/brand/' + brandId + '/issues'
    }

  },
  actions: {
    setActiveArticle (context, article) {
      // console.log('selecting active article action, ' + article)
      context.commit('setActiveArticle', article)
    },
    setActiveArticleSearch (context, article) {
      // console.log('selecting active article search action, ' + article)
      context.commit('setActiveArticleSearch', article)
    },
    setSelectedArticles (context, articles) {
      // console.log('selecting articles action, ' + articles)
      context.commit('setSelectedArticles', articles)
    },
    addSelectedArticle (context, article) {
      // console.log('adding selected article action, ' + article)
      context.commit('addSelectedArticle', article)
    },
    removeSelectedArticle (context, article) {
      // console.log('removing selected article action, ' + article)
      context.commit('removeSelectedArticle', article)
    },
    setSelectedArticlesSearch (context, articles) {
      // console.log('selecting articles search action, ' + articles)
      context.commit('setSelectedArticlesSearch', articles)
    },
    addSelectedArticleSearch (context, article) {
      // console.log('adding selected search article action, ' + article)
      context.commit('addSelectedArticleSearch', article)
    },
    removeSelectedArticleSearch (context, article) {
      // console.log('removing selected search article action, ' + article)
      context.commit('removeSelectedArticleSearch', article)
    },
    setSearchBreadcrumbs (context) {
      // console.log('Setting search breadcrumbs')
      context.commit('setSearchBreadcrumbs')
    },
    setActiveBrandBreadcrumbs (context, activeBrand) {
      // console.log('Setting active brand breadcrumbs' + activeBrand)
      context.commit('setActiveBrandBreadcrumbs', activeBrand)
    },
    setIssueBreadcrumbs (context, { issueID, issueDates }) {
      // console.log('Setting  issue breadcrumbs' + issueID + ' | ' + issueDates)
      context.commit('setIssueBreadcrumbs', { issueID, issueDates })
    },
    setHomeBreadcrumbs (context) {
      // console.log('Setting home breadcrumbs')
      context.commit('setHomeBreadcrumbs')
    },
    setBrandBreadcrumbs (context, { brandId, brandName }) {
      // console.log('Setting brand breadcrumbs' + brandId + ' | ' + brandName)
      context.commit('setBrandBreadcrumbs', { brandId, brandName })
    }

  },
  getters: {

  }
})
