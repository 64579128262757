<template>
  <div>
    <p v-if="$store.state.store_xml_byline === null"><strong>Byline: </strong></p>
    <div v-if="authors"><span v-for="(author, index) in authors.authors" :key="index">
        {{author.name}}
      </span>
    </div>
    <p v-if="$store.state.store_xml_byline"><strong>Byline: </strong><span>{{$store.state.store_xml_byline}}</span></p>
  </div>
</template>

<script>
export default {
  name: 'IssueArticleByline',
  props: {
    authors: {}
  }
}
</script>

<style scoped>

</style>
