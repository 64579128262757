<template >
  <div class="box">
    <div>
      <IssueArticleHeader
        :article="article"
        :publicationName="publicationName"
        :coverDisplayDate="coverDisplayDate"
        :xml_articleName="xml_articleName"
        :xml_summary="xml_summary"
        :xml_publicationName="xml_publicationName"
      />
      <IssueArticlePrimaryImage v-if="article.udf && article.udf.primary_image"
                                :primary_image="article.udf.primary_image"
      />

      <div v-if="article.udf">
        <div
          v-for="(udfBody, index) in article.udf.body"
          :key="index">
          <IssueArticleContent
            :articleBody="udfBody"
          />
        </div>
      </div>
      <div v-if="article.article_xml">
        <div v-html="xml_content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IssueArticleHeader from '@/components/layout/issue/article/IssueArticleHeader.vue'
import IssueArticleContent from '@/components/layout/issue/article/IssueArticleContent.vue'
import IssueArticlePrimaryImage from '@/components/layout/issue/article/IssueArticlePrimaryImage.vue'
import { mapState } from 'vuex'
export default {
  name: 'IssueArticleDetails',
  components: {
    IssueArticleHeader,
    IssueArticleContent,
    IssueArticlePrimaryImage
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    publicationName: {
      type: String,
      required: true
    },
    coverDisplayDate: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      xml_publicationName: '',
      xml_articleName: '',
      xml_summary: '',
      xml_content: ''
    }
  },
  mounted: function () {
    this.display_article_xml_content(this.article)
  },
  beforeUpdate: function () {
    this.display_article_xml_content(this.article)
  },
  methods: {
    display_article_xml_content: function (article) {
      this.xml_byline = ''
      this.xml_text = ''
      this.xml_summary = ''
      var parser = new DOMParser()
      var doc = parser.parseFromString(this.article.article_xml, 'text/xml')
      var docbody = doc.getElementsByTagName('body')
      try {
        this.xml_articleName = doc.getElementsByTagName('dc:title')[0].childNodes[0].nodeValue
        this.xml_publicationName = doc.getElementsByTagName('prism:publicationName')[0].childNodes[0].nodeValue
        for (var l = 0; l < doc.getElementsByTagName('dc:creator').length; l++) {
          this.xml_byline += doc.getElementsByTagName('dc:creator')[l].childNodes[0].nodeValue
          this.xml_byline += ', '
        }
        for (var ii = 0; ii < doc.getElementsByTagName('dc:contributor').length; ii++) {
          this.xml_byline += doc.getElementsByTagName('dc:contributor')[ii].childNodes[0].nodeValue
          this.xml_byline += ', '
        }
        this.$store.state.store_xml_byline = this.xml_byline.slice(0, -2)
      } catch (e) {
        console.log()
      }
      try {
        this.xml_summary = ''
        this.xml_summary = docbody[0].getElementsByClassName('deck')[0].childNodes[0].nodeValue
      } catch (e) {
        console.log()
      }
      var nodes = doc.querySelectorAll('*')
      for (var i = 0; i < nodes.length; i++) {
        if (nodes[i].childNodes.length === 1 && nodes[i].childNodes[0].nodeType === 3) {
          if (nodes[i].tagName === 'p') {
            if (this.xml_summary === nodes[i].textContent) {
              this.xml_text += ''
            } else {
              this.xml_text += '<br/>' + nodes[i].textContent + '<br/>'
            }
          }
          if (nodes[i].tagName === 'dc:type') {
            this.xml_text += '<br/><b>' + '[IMAGES]' + '</b><br/>'
          }
          if (nodes[i].tagName === 'pam:caption') {
            this.xml_text += '<br/><i>' + nodes[i].textContent + '</i><br/>'
          }
          if (nodes[i].tagName === 'pam:credit') {
            this.xml_text += '<br/><small>' + nodes[i].textContent + '</small><br/>'
          }
          if (nodes[i].tagName === 'div') {
            this.xml_text += '<br/><b>' + '[BOX]' + '</b><br/>'
          }
          if (nodes[i].tagName === 'h1') {
            this.xml_text += '<br/><b>' + nodes[i].textContent + '</b><br/>'
          }
        } if (nodes[i].childNodes.length !== 1) {
          if (nodes[i].tagName === 'p') {
            this.xml_text += '<br/>' + nodes[i].textContent + '<br/>'
          }
        }
      }
      for (var j = 0; j < docbody.length; j++) {
        try {
          if (docbody[0].getElementsByTagName('TimeInc:PLSpict')[j].nodeName === 'TimeInc:PLSpict') {
            this.xml_text += '<br/><b>[IMAGES]</b><br/>'
            for (var n = 0; n < docbody[0].getElementsByTagName('TimeInc:PLSpict').length; n++) {
              this.xml_text += '<br/>' + docbody[0].getElementsByTagName('TimeInc:PLSpict')[n].childNodes[0].nodeValue + '<br/>'
            }
          }
        } catch (e) {
          console.log()
        }
      }
      this.xml_content = this.xml_text.slice(this.xml_text.indexOf('<br/>') + 5, this.xml_text.length)
    }
  },
  computed: {
    ...mapState([
      'activeArticle',
      'selectedArticles'
    ])
  }
}
</script>

<style scoped>
</style>
