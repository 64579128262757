<template>
  <div id="home">
    <PageNotification :loading="loading" :errored="errored" />

    <div class="columns is-mobile is-multiline" v-if="success" >
      <div class="column is-one-fifth " v-for="(brand, index) in brands" :key=brand.id>
        <a @click="showIssues(brand.id,brand.name)">
        <div class="card ">
          <div class="card-image">
            <figure class="image is-134x89 center">
              <img class=" " :src="require('../../public/images/sf_logo/' + logos[index])" :alt="brand.name">
            </figure>
          </div>
        </div>
        </a>
        <br/>
      </div>
    </div>

  </div>
</template>

<script>
import PageNotification from '@/components/ui/PageNotification.vue'
import { mapActions } from 'vuex'
import Vue from 'vue'
import { inject } from 'vue'
import axios from 'axios'

export default {
  name: 'home',
  components: {
    PageNotification
  },
  data () {
    return {
      urlLink: this.lambdaUrl, 
      apiKey: this.apikey, 
      brands: {},
      loading: false,
      errored: false,
      success: false,
      logos: ['allrecipes.png', 'allyou.png', 'betterhomesgardens.png', 'business.png', 'coastalliving.png', 'cookinglight.png', 'countrygardens.png', 'diabeticliving.png', 'doityourself.png', 'eatingwell.png', 'entertainment.png', 'familycircle.png', 'finecooking.png', 'foodwine.png', 'health.png', 'homesgardens.png', 'Instyle.png', 'life.png', 'marthastewart.png', 'midwestliving.png', 'millie.png', 'parents.png', 'parentsLatina.png', 'people.png', 'peoplestyle.png', 'peopleenspanol.png', 'rachaelray.png', 'realsimple.png', 'reveal.png', 'shape.png', 'southernliving.png', 'successfulfarming.png', 'magnolia.png', 'traditionalhome.png', 'travelleisure.png', 'wood.png']
    }
  },
  mounted: async function () {
    // console.log(this.$route.path)
    // console.log("Home")
    console.log(this.urlLink)
    this.setHomeBreadcrumbs()
    this.loading = true
    axios.get(this.urlLink + '/brands', {headers: {'x-api-key': this.apiKey}})
      .then(response => {
        this.brands = response.data.data
        // console.log(this.brands.length)
        for (var i = 0; i < this.brands.length; i++) {
          if (this.brands[i].name === 'All Recipes') {
            this.brands[i].name = 'Allrecipes'
          } else if (this.brands[i].name === 'Finecooking') {
            this.brands[i].name = 'Fine Cooking'
          } else if (this.brands[i].name === 'Rachael Ray Magazine') {
            this.brands[i].name = 'Rachael Ray In Season'
          } else if (this.brands[i].name === 'Martha Stewart') {
            this.brands[i].name = 'Martha Stewart Living'
          }
        }
        // console.log(this.brands[0].name)
        this.success = true
      })
      .catch(error => {
        console.log(error)
        this.errored = true
        this.success = false
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
  },
  methods: {
    ...mapActions({
      setHomeBreadcrumbs: 'setHomeBreadcrumbs',
      setBrandBreadcrumbs: 'setBrandBreadcrumbs'
    }),
    showIssues: function (brandId, brandName) {
      this.setBrandBreadcrumbs({ brandId, brandName })
      this.$router.push({ name: 'issue_gallery', params: { 'brandId': brandId } })
    }
  }
}
</script>

<style scoped>
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
</style>
