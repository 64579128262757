<template>
  <div id="pageNotification" class="progressBar">
    <PageNotificationError v-if="errored"/>
    <PageNotificationLoading v-if="loading" />

  </div>

</template>

<script>
import PageNotificationError from '@/components/ui/PageNotificationError.vue'
import PageNotificationLoading from '@/components/ui/PageNotificationLoading.vue'
export default {
  name: 'PageNotification',
  components: {
    PageNotificationError,
    PageNotificationLoading
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    errored: {
      type: Boolean,
      required: true
    }

  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
  .progressBar {
    margin: auto;
    position: fixed;
    top: 40%;
    bottom: 50%;
    left: 35%;
    right: 35%;
    color: #FFF;
    z-index: 5;
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    /*  !* smartphones, iPhone, portrait 480x320 phones *!*/
    .progressBar {
      margin: auto;
      position: fixed;
      top: 35%;
      bottom: 30%;
      left: 15%;
      right: 15%;
      color: #FFF;
      z-index: 5;
    }
  }
</style>
