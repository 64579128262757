<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Storyfinder 2.0</strong> by <a href="https://meredith.com">Meredith Corp</a>. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer {
    padding: unset;
    position: fixed;
    left: 35%;
    right: 35%;
    bottom: 0%;
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    /*  !* smartphones, iPhone, portrait 480x320 phones *!*/
    .footer {
      padding: unset;
      position: fixed;
      left: 10%;
      right: 10%;
      bottom: 0%;
    }
  }

</style>
