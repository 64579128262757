<template>
  <div id="app">
    <NavBar v-if="authState?.isAuthenticated"/>
    <BreadCrumb v-if="authState?.isAuthenticated"/>
    <router-view :key="$route.fullPath"></router-view>
    <FooterView v-if="authState?.isAuthenticated"/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'app',
  components: { NavBar, BreadCrumb, FooterView },
  methods: {    
  }
}
</script>

<style>

</style>
