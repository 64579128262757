<template>
  <div>
    <article class="message is-primary">
      <div class="message-body">
        Loading...
        <progress class="progress is-primary" max="100">15%</progress>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'PageNotificationLoading',
  props: {

  }
}
</script>

<style scoped>

</style>
