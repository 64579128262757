<template>
  <section class="section">
    <div class="notification is-light">{{errorMessage}}</div>
  </section>

</template>

<script>
export default {
  name: 'PageNotificationError',
  props: {

  },
  data () {
    return {
      errorMessage: "We're sorry, we're not able to retrieve this information at the moment, please try back later"
    }
  }
}
</script>

<style scoped>

</style>
