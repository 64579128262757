<template>
  <article class="media" v-if="articleBody">
    <div class="media-content">
      <div class="content" v-if="articleBody._type === 'image'">
        <strong>[IMAGES]</strong>
        <div v-html="articleBody.caption"></div><br/>
      </div>
      <div class="content" v-if="articleBody._type === 'paragraph'">
        <div v-html="articleBody.content"></div><br/>
      </div>
      <div class="content" v-if="articleBody._type === 'blockquote'">
        <div v-html="articleBody.quote"></div><br/>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'IssueArticleContent',
  props: {
    articleBody: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
