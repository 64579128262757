<template>
  <div id="issueDetails" class="container is-fluid" >
    <PageNotification :loading="loading" :errored="errored" />
    <div class="columns is-mobile is-multiline" v-if="success">
<!--      <div class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd ">-->
      <div class="column is-three-quarters-mobile is-two-thirds-tablet is-one-third is-one-third-widescreen is-one-quarter-fullhd">
        <nav class="panel heightreset">
          <div class="fixedheader">
          <p class="panel-heading sidebarheader">Table Of Contents</p>
          <div class="panel-tabs ">
            <div class="buttons are-small ">
              <a class="button showPointer" v-on:click="UnSelectAll()"><span class="icon is-small"><i class="fas fa-times-circle"></i></span>&nbsp;Clear All</a>
              <a class="button showPointer" v-on:click="download_selected_html(selectedArticles)"><span class="icon is-small"><i class="fas fa-download"></i></span>&nbsp;Download</a>
              <a class="button showPointer" v-on:click="print"><span class="icon is-small"><i class="fas fa-print"></i></span>&nbsp;Print</a>
              <a class="button showPointer" :href="issue.issue_pdf.asset_path_signed" target="_blank" v-if="issue.issue_pdf"><span class="icon"><i class="fas fa-file-pdf"></i></span>&nbsp;Issue PDF</a>
            </div>
          </div>
          </div>
          <div class="brtype1"><br></div>
          <div class="brtype2"><br><br></div>
          <IssueSidebar
            v-for="(article, index) in issue.issue_cover"
            :key="index"
            :article="article"
          />
          <IssueSidebar
            v-for="(article, index) in issue.issue_article"
            :key="`B-${index}`"
            :article="article"
          />
          <div class="brtype2"><br><br></div>
        </nav>
      </div>
      <div class="column ">
        <nav class="panel heightreset">
          <div class="fixedheader1">
          <p class="panel-heading">
            Article
          </p>
          <div class="panel-tabs" style="justify-content: left;">
            <div class="buttons are-small  ">
              <a class="button" v-on:click="active_print"><span class="icon is-small"><i class="fas fa-print"></i></span>&nbsp;Print</a>
            </div>
          </div>
          </div>
          <div class="brtype2"><br><br><br></div>
          <div>
            <div id="active_arti">
              <div class="panel-block" v-if="activeArticle===Object"><div class="notification" >{{noActiveArticleMsg}}</div></div>
              <div class="panel-block" v-if="activeArticle!=Object && (issue.issue_article.length === 0 && (issue.issue_cover==undefined?0:issue.issue_cover.length) === 0)"><div class="notification" >{{noActiveArticleMsg}}</div></div>
              <div class="panel-block" style="display: block" v-if="activeArticle!=Object && (issue.issue_article.length !== 0 || (issue.issue_cover==undefined ? 0 : issue.issue_cover.length) !== 0)">
                <IssueArticleDetails
                  :article="activeArticle"
                  :publicationName="issue.issue_publicationName==undefined?'':issue.issue_publicationName"
                  :coverDisplayDate="issue.issue_coverDisplayDate"
                />
              </div>
            </div>
          </div>
          <div ref="panelBlock" style="display: none" id="selected_arti">
            <div class="panel-block" v-if="selectedArticles.length === 0"><div class="notification" >{{noActiveArticleMsg}}</div></div>
            <div class="panel-block" v-for="(item,index) in selectedArticles" :key="index" style="display: block">
              <IssueArticleDetails
                :article="item"
                :publicationName="issue.issue_publicationName==undefined?'':issue.issue_publicationName"
                :coverDisplayDate="issue.issue_coverDisplayDate"
              />
            </div>
          </div>
          <div class="brtype2"><br><br></div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import PageNotification from '@/components/ui/PageNotification.vue'
import IssueSidebar from '@/components/layout/issue/IssueSidebar.vue'
import IssueArticleDetails from '@/components/layout/issue/article/IssueArticleDetails.vue'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'IssueDetails',
  components: {
    PageNotification,
    IssueSidebar,
    IssueArticleDetails
  },
  methods: {
    download_selected_html: function (articles) {
      const url = window.URL.createObjectURL(new Blob([this.$refs.panelBlock.innerHTML]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'StoryFinderResult.html')
      document.body.appendChild(link)
      link.click()
    },
    print () {
      const prtHtml = document.getElementById('selected_arti').innerHTML
      let stylesHtml = ''
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      }
      const WinPrint = window.open('', '', 'width=500px,left=0,toolbar=0,scrollbars=0,status=0')
      WinPrint.document.write(`<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    active_print () {
      const prtHtml = document.getElementById('active_arti').innerHTML
      let stylesHtml = ''
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      }
      const WinPrint = window.open('', '', 'width=500px,left=0,toolbar=0,scrollbars=0,status=0')
      WinPrint.document.write(`<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    UnSelectAll: function () {
      var items = document.getElementsByTagName('input')
      for (var i = 0; i < items.length; i++) {
        if (items[i].type === 'checkbox' && items[i].checked === true) {
          items[i].click()
        }
      }
    }
  },
  data () {
    return {
      urlLink: this.lambdaUrl, 
      apiKey: this.apikey, 
      issueId: this.$route.params.issueId,
      issue: {},
      loading: false,
      errored: false,
      success: false,
      noActiveArticleMsg: 'Please select an article to see a content'
    }
  },
  unmounted () {
    const el = document.body
    el.classList.remove('scroll-off')
  },
  mounted: function () {
    const el = document.body
    el.classList.add('scroll-off')

    // console.log('Route path', this.$route.path)
    this.loading = true
    var urlIssues = this.urlLink + '/issue?issueId=' + this.issueId
    console.log(urlIssues)
    this.$http
      .get(urlIssues, {headers: {'x-api-key': this.apiKey}})
      .then(response => {
        this.$store.commit('setActiveArticle', Object)
        this.issue = response.data.data
        // console.log(this.issue)
        this.success = true
        if (this.issue.issue_article.length !== 0 || (this.issue.issue_cover == undefined ? 0 : this.issue.issue_cover.length) !== 0) {
          this.noActiveArticleMsg = 'Please select an article to see a content'
        } else {
          this.noActiveArticleMsg = 'This issue does not have any articles yet. If this is a newly published issue please check back later.'
        }
      })
      .catch(error => {
        console.log(error)
        this.errored = true
        this.success = false
      })
      .finally(() => {
        this.loading = false
      })
    console.log('Acive Article Id', this.activeArticle.$id)
  },
  computed: {
    ...mapState([
      'activeArticle',
      'selectedArticles'
    ])
  }
}

</script>

<style scoped>
  .panel {
    max-height: 800px;
    overflow: auto;
  }
  .section {
    padding-top: unset;
  }

  .subheader {
    font-size: 14px;
    color: black;
    display: inline-block;
    margin-top: 5px;
  }

  .fixedheader {
    overflow: hidden;
    position: fixed;
    margin-top: 0%;
    width: calc(34% - 47px);
    background-color: #f5f5f5;
  }

  .fixedheader1 {
    overflow: hidden;
    position: fixed;
    margin-top: 0%;
    width: calc(66% - 41px);
    background-color: #f5f5f5;
  }

  .scroll-off {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
    padding-bottom: 1px;
  }

  .heightreset {
    max-height: 90vh;
  }

  .sidebarheader {
    font-size: 18px;
  }

  @media (max-width:1152px) {
    .showPointer, .btn1, .btn2 {
      font-size: 0.5rem !important;
    }
    .brtype1 {
      display: none;
    }
    .sidebarheader {
      font-size: 14px;
    }
  }
  @media (max-width:1150px) {
    .fixedheader{
      width: 305px;
    }
    .fixedheader1 {
      width: 640px;
    }
    .showPointer, .btn1, .btn2 {
      font-size: 0.5rem !important;
    }
    .brtype1 {
      display: none;
    }
  }

  @media (max-width:900px) {
    .fixedheader{
      width: 258px;
    }
    .fixedheader1 {
      width: 640px;
    }
    .showPointer, .btn1, .btn2 {
      font-size: 0.5rem !important;
    }
  }

  @media (max-width:800px) {
    .fixedheader{
      width: 224px;
    }
    .fixedheader1 {
      width: 640px;
    }
    .showPointer, .btn1, .btn2 {
      font-size: 0.5rem !important;
    }
    .sidebarheader {
      font-size: 14px;
    }
  }

  @media (max-width:700px) {
    .fixedheader{
      width: 178px;
    }
    .fixedheader1 {
      width: 640px;
    }
    .showPointer, .btn1, .btn2 {
      font-size: 0.4rem !important;
    }
    .sidebarheader {
      font-size: 11px;
    }
    .subheader {
      font-size: 10px;
    }
  }

  @media (max-width:550px) {
    .fixedheader{
      width: 144px;
    }
    .fixedheader1 {
      width: 640px;
    }
    .showPointer, .btn1, .btn2 {
      font-size: 0.4rem !important;
    }
    .sidebarheader {
      font-size: 8px;
    }
    .subheader {
      font-size: 6px;
    }
  }
</style>
