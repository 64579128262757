<template>
  <div class="panel-block">
    <span class="has-text-grey has-text-weight-bold" v-if="this.article.article_startingPage">{{ article.article_startingPage }}&nbsp;</span>
    <span class="has-text-grey has-text-weight-bold" v-if="this.article.article_coverPage">{{ article.article_coverPage }}&nbsp;</span>
    <input type="checkbox" :value="article.$id" v-on:click="updateSelectedArticles(article)" ref="rolesSelected">&nbsp;
    <label @click="selectActiveArticles(article)" style="width: 100%;cursor: pointer;"  :class="{'is-active':(article.$id === activeArticle.$id), 'has-background-warning': (article.$id === activeArticle.$id)}">
      <div class="content">
        <span class="is-size-7 has-text-weight-bold" v-if="this.article.udf">{{article.$name}}</span>
        <span class="is-size-7 has-text-weight-bold" v-if="this.article.article_xml">{{xml_articleName}}</span>
        <br/>
      </div>
    </label>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'IssueSidebar',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      rolesSelected: '',
      xml_articleName: '',
      xml_summary: '',
      xml_publicationName: '',
      xml_byline: '',
      xml_text: ''
    }
  },
  mounted: function () {
    this.display_article_xml_toc_content(this.article)
  },
  methods: {
    ...mapActions({
      selectActiveArticle: 'setActiveArticle',
      addSelectedArticle: 'addSelectedArticle',
      removeSelectedArticle: 'removeSelectedArticle',
      updateSelectedArticles: function (article) {
        console.log(this.article)
        var action = this.$refs.rolesSelected.checked === false ? this.removeSelectedArticles(this.article) : this.addSelectedArticles(this.article)
        console.log('Selected article checkbox state', action)
      },
      addSelectedArticles (article) {
        this.$store.commit('addSelectedArticle', this.article)
        this.selectActiveArticles(this.article)
      },
      removeSelectedArticles (article) {
        this.$store.commit('removeSelectedArticle', this.article)
        this.$store.commit('setActiveArticle', Object)
      },
      selectActiveArticles (article) {
        this.$store.commit('setActiveArticle', this.article)
      },
      display_article_xml_toc_content: function (article) {
        this.xml_summary = ''
        var parser = new DOMParser()
        var doc = parser.parseFromString(this.article.article_xml, 'text/xml')
        var docbody = doc.getElementsByTagName('body')
        try {
          this.xml_articleName = doc.getElementsByTagName('dc:title')[0].childNodes[0].nodeValue
          this.xml_summary = docbody[0].getElementsByTagName('p')[0].childNodes[0].nodeValue
        } catch (e) {
          console.log()
        }
      }
    })
  },
  computed: {
    ...mapState([ 'activeArticle', 'selectedArticles' ])
  }
}
</script>
<style scoped>
  .has-background-warning {
    background-color: hsl(54, 90%, 60%, 0.41) !important;
  }
</style>
