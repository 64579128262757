<template>
  <div id="issueGallery" ref="issueGallery">
    <PageNotification :loading="loading" :errored="errored" />
    &nbsp;Date Range: {{this.slide1}}&nbsp;<label id="year_label">{{this.slide2}}</label>
    <section class="range-slider">
      <span class="rangeValues"></span>
      <input value="" id="s1" min="" max="" step="1" type="range" @change="range_slider()" >
      <div id="bubble" style="position: absolute; top:-50px; width: 55px; height:25px; pointer-events: none; opacity: 0;"></div>
      <input value="" id="s2" min="" max="" step="1" type="range" @change="range_slider()" >
      <div id="bubble1" style="position: absolute; top:-50px; width: 55px; height:25px; pointer-events: none; opacity: 0;"></div>
    </section>
    <div class="site-content">
      <div class="columns is-multiline is-mobile centeritems" v-if="success">
        <div class="column is-fixedpx is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd" v-for="issue in issues" :key=issue.id>
          <router-link :to="{ name: 'issue', params: { 'issueId': issue.id }}" >
            <div class="card" @click="showIssueDetails(issue.id,issue.issue_date)">
              <div v-if="issue.logo===''" class="card-content">
                <p class="has-text-centered has-text-weight-bold is-size-2">
                  Cover image not available
                </p>
              </div>
              <div class="card-image" v-if="issue.logo!=''">
                <figure class="image ">
                  <img :src=issue.logo :alt="issue.name">
                </figure>
              </div>
              <div class="card-header-title is-centered">{{issue.name}}</div>
            </div>
          </router-link>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .card .card-image,
  .card .card-content,
  .card .card-image .image img {
    height: 300px;
  }
  .image {
    width: 225px;
  }
  .is-fixedpx {
    width: 249px;
  }
  .centeritems {
    align-items: center;
    margin-left: calc(4% - 22px);
    margin-right: calc(4% - 22px);
  }
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    /*  !* smartphones, iPhone, portrait 480x320 phones *!*/
    .is-fixedpx {
      width: 249px;
    }
    .image {
      width: 225px;
    }
    .centeritems {
      align-items: center;
      margin-left: calc(30% - 50px);
      margin-right: calc(30% - 50px);
    }
  }
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)  {
    /*iPad and iPad Pro*/
    .centeritems {
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  section.range-slider {
    position: relative;
    width: 200px;
    height: 35px;
    text-align: center;
  }
  section.range-slider input {
    pointer-events: none;
    position: absolute;
    overflow: visible;
    left: 136px;
    top: -23px;
    width: 300px;
    outline: none;
    height: 18%;
    margin: 5px;
    padding: 0;
  }
  section.range-slider input::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
  }
  section.range-slider input::-moz-range-thumb {
    pointer-events: all;
    -moz-appearance: none;
    background: lightgray;
    border: 2px solid lightgray;
    width: 10px;
  }
  section.range-slider input::-moz-range-track {
    background-color: lightgray;
    border: 1px solid lightgray;
    -moz-appearance: none;
  }
  section.range-slider input:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background-color: lightgray;
    border: 1px solid lightgray;
  }
  input[type='range'], input[type='range']:focus, input[type='range']:active, input[type='range']::-moz-focus-inner, input[type='range']:-moz-focusring {
    border: 1px solid lightgray;
    outline-offset: 2px;
  }
  section.range-slider input[type=range]::-moz-focus-outer {
    border: 0;
  }
  #year_label{
    margin-left:315px; height:15px;
  }
  .site-content {
    overflow-y: scroll;
    transform-origin: top left;
    position: absolute;
    height: 100%;
    /* scrollbar-width: none; */
    -ms-overflow-style: none;
  }
</style>
<script>
import PageNotification from '@/components/ui/PageNotification.vue'
import Vue from 'vue'
export default {
  name: 'IssueGallery',
  components: {
    PageNotification
  },
  data () {
    return {
      urlLink: this.lambdaUrl, 
      apiKey: this.apikey, 
      brandId: this.$route.params.brandId,
      issues: [],
      loading: false,
      errored: false,
      success: false,
      data: [],
      busy: false,
      searchafterid: '0',
      slide1: '0',
      slide2: '0',
      publicationwiseIssueLimit: '',
      slide1_dict: '',
      slide2_dict: '',
      oldSliderVal: -1,
      oldSliderVal1: -1,
      fullyLoaded: false,
      publicationIssueLimit: -1,
      publicationIssueMaxDate: '',
      xpos: 0,
      ypos: 0
    }
  },
  mounted: function () {
    this.$store.activeBreadCrumb = ''
    this.$store.state.activeIssue = ''
    this.$store.state.activeIssueUrl = ''
    this.set_date_range_value()
    document.getElementById('s1').setAttribute('max', new Date().getMonth() == 11 ? new Date().getFullYear() + 1 : new Date().getFullYear())
    document.getElementById('s2').setAttribute('max', new Date().getMonth() == 11 ? new Date().getFullYear() + 1 : new Date().getFullYear())
    document.getElementById('s2').value =  new Date().getMonth() == 11 ? new Date().getFullYear() + 1 : new Date().getFullYear()
    document.getElementById('s1').value = this.slide1
    document.getElementById('s1').setAttribute('min', this.slide1)
    document.getElementById('s2').setAttribute('min', this.slide1)
    this.slide2 = new Date().getMonth() == 11 ? new Date().getFullYear() + 1 : new Date().getFullYear()

    var bubble = document.getElementById('bubble')
    var sliderInput = document.getElementById('s1')
    var show = function (e) {
      bubble.style.left = e.clientX - (bubble.offsetWidth / 2) + 'px'
      bubble.style.opacity = '1'
      
    }
    var hide = function () {
      bubble.style.opacity = '0'
      this.slide1 = sliderInput.value
    }
    var moveBubble = function (e) {
      if (this.oldSliderVal !== '0' && this.oldSliderVal !== '100') {
        bubble.style.left = e.clientX - (bubble.offsetWidth / 2) + 'px'
      }
      var sliderVal = sliderInput.value
      bubble.innerHTML = sliderVal
      this.oldSliderVal = sliderVal
      
    }
    
    sliderInput.addEventListener('mousemove', moveBubble)
    sliderInput.addEventListener('mousedown', show)
    sliderInput.addEventListener('mouseup', hide)

    // bubble1
    var bubble1 = document.getElementById('bubble1')
    var sliderInput1 = document.getElementById('s2')
    var show1 = function (e) {
      bubble1.style.left = e.clientX - (bubble1.offsetWidth / 2) + 'px'
      bubble1.style.opacity = '1'
      
    }
    var hide1 = function () {
      bubble1.style.opacity = '0'
      this.slide2 = sliderInput1.value
    }
    var moveBubble1 = function (e) {
      if (this.oldSliderVal1 !== '0' && this.oldSliderVal1 !== '100') {
        bubble1.style.left = e.clientX - (bubble1.offsetWidth / 2) + 'px'
      }
      var sliderVal1 = sliderInput1.value
      bubble1.innerHTML = sliderVal1
      this.oldSliderVal1 = sliderVal1
      
    }
    sliderInput1.addEventListener('mousemove', moveBubble1)
    sliderInput1.addEventListener('mousedown', show1)
    sliderInput1.addEventListener('mouseup', hide1)
    
    // console.log(this.slide1 + '/' + this.slide2)
    this.issues = []
    this.searchafterid = '0'
    this.getIssues()

    // Detect when scrolled to bottom.
    const listElm = document.querySelector('.site-content');
    listElm.addEventListener('scroll', e => {
      if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.loadMore();
      }
    });

    document.onkeydown = (e) => {
        // console.log(e.key);   
        if (e.key == 'ArrowUp') {
              // up arrow
              // console.log("Key up");
              e.preventDefault();
              this.keyupScroll(true);
          }
          else if (e.key == 'ArrowDown') {
              // down arrow
              // console.log("Key down");
              e.preventDefault();
              this.keydownScroll(true);
          }
          else if (e.key == 'PageUp') {
              // down arrow
              // console.log("Page up");
              e.preventDefault();
              this.keyupScroll(false);
          }
          else if (e.key == 'PageDown') {
              // down arrow
              // console.log("Page down");
              e.preventDefault();
              this.keydownScroll(false);
          }
      }
  },
  methods: {
    keyupScroll: function (isArrowUp) {
        const listElm = document.querySelector('.site-content');
        if(listElm.scrollTop==0) {
          this.ypos = 0;
        }
        if(this.ypos<=0) {
          this.ypos=0;
        } else if(isArrowUp){
          this.ypos = this.ypos-100;
        } else if(!isArrowUp){
          this.ypos = this.ypos-600;
        }        
        listElm.scroll(this.xpos, this.ypos);
        // console.log("keyup scroll function:"+this.xpos+","+this.ypos);
    },
    keydownScroll: function (isArrowDown) {
        const listElm = document.querySelector('.site-content');
        if(listElm.scrollTop==0) {
          this.ypos = 0;
        }
        if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          console.log("load more");
        } else if(isArrowDown){
          this.ypos = this.ypos+100;
        } else if(!isArrowDown){
          this.ypos = this.ypos+600;
        }        
        listElm.scroll(this.xpos, this.ypos);
        // console.log("keydown scroll function:"+this.xpos+","+this.ypos+","+listElm.scrollTop);
    },
    showIssueDetails: function (issueID, issueDates) {
      var issueDate = issueDates.split('T')[0]
      this.$store.state.activeBreadCrumb = ''
      this.$store.state.activeIssue = issueDate
      this.$store.state.activeIssueUrl = '/issue/' + issueID
      this.$router.push({ name: 'issue', params: { 'issueId': issueID } })
    },
    loadMore: function () {
      console.log('In load more ' + this.searchafterid)
      if (!this.fullyLoaded) {
        this.getIssues()
      }
    },
    range_slider: function () {
      this.slide1 = document.getElementById('s1').value
      this.slide2 = document.getElementById('s2').value

      // console.log(this.slide1 + '/' + this.slide2)
      this.issues = []
      this.searchafterid = '0'
      this.getIssues()
    },
    getIssues: function () {
      this.loading = true
      if (!this.busy) {
        this.busy = true
        var urlBrandIssues = this.urlLink + '/brandissues?brandId=' + this.brandId + '&startyear=' + this.slide1 + '&endyear=' + this.slide2 + '&searchafterid=' + this.searchafterid
        // console.log(urlBrandIssues)
        this.$http
          .get(urlBrandIssues, {headers: {'x-api-key': this.apiKey}})
          .then(response => {
            // console.log(response)
            if (response.data.data.length === 0) {
              this.fullyLoaded = true
            } else {
              for (var i = 0; i < response.data.data.length; i++) {
                var issue_date = ''
                if (this.publicationIssueLimit != -1) {
                  if (response.data.data[i].issue_digitalOnSaleDate != '') {
                    issue_date = new Date(response.data.data[i].issue_digitalOnSaleDate)
                  } else {
                    issue_date = new Date(response.data.data[i].issue_date)
                  }
                  if (i == 0) {
                    if (response.data.data[i].issue_date != '') {
                      if (new Date(response.data.data[i].issue_date).getFullYear() != new Date().getFullYear() + 1) {
                        document.getElementById('s1').setAttribute('max', new Date().getFullYear())
                        document.getElementById('s2').setAttribute('max', new Date().getFullYear())
                        document.getElementById('s2').setAttribute('value', new Date().getFullYear())
                        // this.slide2 = new Date().getFullYear()
                      }
                    } else if (issue_date.getFullYear() != new Date().getFullYear() + 1) {
                      document.getElementById('s1').setAttribute('max', new Date().getFullYear())
                      document.getElementById('s2').setAttribute('max', new Date().getFullYear())
                      document.getElementById('s2').setAttribute('value', new Date().getFullYear())
                      // this.slide2 = new Date().getFullYear()
                    }
                  }
                  if (issue_date <= this.publicationIssueMaxDate) {
                    this.issues.push(response.data.data[i])
                  }
                } else {
                  this.issues.push(response.data.data[i])
                  if (i == 0) {
                    if (new Date(response.data.data[i].issue_date).getFullYear() != new Date().getFullYear() + 1) {
                      document.getElementById('s1').setAttribute('max', new Date().getFullYear())
                      document.getElementById('s2').setAttribute('max', new Date().getFullYear())
                      document.getElementById('s2').setAttribute('value', new Date().getFullYear())
                      // this.slide2 = new Date().getFullYear()
                    }
                  }
                }
              }
              this.fullyLoaded = false
              this.searchafterid = response.data.search_after
            }
            this.success = true
            this.busy = false
          })
          .catch(error => {
            console.log(error)
            this.errored = true
            this.success = false
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 1000)
          })
      }
    },
    set_date_range_value: function () {
      this.publicationwiseIssueLimit = this.pubWiseIssueLimit
      for (var key in this.publicationwiseIssueLimit) {
        if (this.brandId === key) {
          this.slide1 = this.publicationwiseIssueLimit[key].split(',')[0]
          this.publicationIssueLimit = parseInt(this.publicationwiseIssueLimit[key].split(',')[1])
          if (this.publicationIssueLimit != -1) {
            this.publicationIssueMaxDate = new Date()
            this.publicationIssueMaxDate.setDate(this.publicationIssueMaxDate.getDate() + this.publicationIssueLimit)
          }
        }
      }
    }
  }
}
</script>
