<template>
  <nav class="breadcrumb is-small has-succeeds-separator" aria-label="breadcrumbs">
    <ul>
      <li><router-link class="navbar-item homebreadcrumb" to="/"><span class="icon"><i class="fas fa-home"></i></span>&nbsp;Home</router-link></li>
      <li v-if="$store.state.activeBrand"><router-link :to="$store.state.activeBrandUrl">{{$store.state.activeBrand}}</router-link></li>
      <li v-if="$store.state.activeIssue"><router-link :to="$store.state.activeIssueUrl">{{$store.state.activeIssue}}</router-link></li>
      <li v-if="$store.state.activeBreadCrumb" class="is-active"><a href="#" aria-current="page">{{$store.state.activeBreadCrumb}}</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'BreadCrumb' 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .breadcrumb a.router-link-exact-active {
    color: #42b983;
  }

  .homebreadcrumb{
    top: -2px;
  }
</style>
