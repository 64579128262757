<template>
  <article class="media">
    <div class="media-content">
      <div class="content" v-if="primary_image._type === 'image'">
        <strong>[IMAGES]</strong>
        <div v-html="primary_image.caption"></div><br/>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'IssueArticlePrimaryImage',
  props: {
    primary_image: {}
  }
}
</script>

<style scoped>

</style>
