import { createRouter, createWebHistory } from 'vue-router'
import { LoginCallback, navigationGuard } from '@okta/okta-vue'
import Home from '../views/Home.vue'
import IssueGallery from '../views/IssueGallery'
import IssueDetails from '../views/IssueDetails'

const router = createRouter({
  history: createWebHistory("storyfinder"),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/oktasuccess',
      component: LoginCallback
    },
    {
      path: '/brand/:brandId/issues',
      name: 'issue_gallery',
      component: IssueGallery,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/issue/:issueId',
      name: 'issue',
      component: IssueDetails,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/documentation',
      name: 'documentation',
      component: () => import(/* webpackChunkName: "about" */ '../views/Documentation.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../views/Searchnew.vue'),
      props: (route) => ({ query: route.query.q }),
      meta: {
        requiresAuth: true
      }
    },
    // {
    //   path: '/*',
    //   component: Home
    // }
  ]
})

router.beforeEach(navigationGuard)

export default router
