<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <div class="columns">
          <div class="column is-2" id="col_31">
            <label v-if="article.article_pdf && article.article_pdf_spread" class="tag is-light">
              Full Article, Single Pages
            </label>
            <label v-else-if="article.article_pdf" class="tag is-light" style="margin-left: 20px;">
              Full Article, Single Pages
            </label>
            <a v-if="article.article_pdf"
               :href="article.article_pdf.asset_path_signed"
               target="_blank"
            >
                <span v-if="article.article_pdf" v-html="imgHtml">
                </span>
            </a>
          </div>
          <div class="column is-2" id="col_32">
            <label v-if="article.article_pdf_spread" class="tag is-light" style="margin-left: 20px;">
              Article Spread(s)
            </label>
            <a v-if="article.article_pdf_spread"
               :href="article.article_pdf_spread.asset_path_signed"
               target="_blank"
            >
                <span v-if="article.article_pdf_spread" v-html="imgHtml">
                </span>
            </a>
          </div>
          <div class="column is-8">
            <h1 class="title is-1 has-text-centered" v-if="article.udf">{{publicationName==""?(article.udf.brand==undefined?"":""):publicationName}}</h1>
            <h1 class="title is-1 has-text-centered" v-if="article.article_xml">{{xml_publicationName}}</h1>
            <p class="subtitle is-6 has-text-centered" v-if="article.udf">{{coverDisplayDate}}</p>
            <p class="subtitle is-6 has-text-centered" v-if="article.article_xml">{{coverDisplayDate}}</p>
            <h2 class="subtitle is-4 has-text-centered" v-if="article.udf">{{article.$name}}</h2>
            <h2 class="subtitle is-4 has-text-centered" v-if="article.article_xml">{{xml_articleName}}</h2>
            <p class="subtitle is-6 " v-if="article.udf">{{article.udf.summary}}</p>
            <p class="subtitle is-6 " v-if="article.article_xml && xml_summary">{{xml_summary}}</p>
            <IssueArticleByline v-if="article.udf" :authors="article.udf.authors"/>
            <IssueArticleByline v-if="article.article_xml" :authors="article.article_xml"/>
            <p style="margin: 0em;" v-if="article.article_xml"><strong>Page: </strong>{{article.article_coverPage}}{{article.article_startingPage}}<strong style="padding-left: 17em;">Words: </strong>{{article.article_wordCount}}</p>
            <p v-if="article.article_section"><strong>Section: </strong> {{article.article_section}} </p>
          </div>
        </div>
      </div><hr>
    </div>
  </article>
</template>
<script>
import IssueArticleByline from '@/components/layout/issue/article/IssueArticleByline.vue'
export default {
  name: 'IssueArticleHeader',
  components: {
    IssueArticleByline
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    publicationName: {
      type: String,
      required: true
    },
    coverDisplayDate: {
      type: String,
      required: true
    },
    xml_publicationName: {
      type: String,
      required: true
    },
    xml_articleName: {
      type: String,
      required: true
    },
    xml_summary: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
      imgHtml: ''
    }
  },
  mounted: function () {
    this.updateImage()
  },
  beforeUpdate: function () {
    this.updateImage()
  },
  methods: {
    updateImage: function () {
      if (this.article.asset_thumbnail !== undefined) {
        if (this.article.article_pdf_spread !== undefined) {
          this.imgHtml = '<figure class="image is-96x96" style="margin-bottom: 112px;position: unset !important;">' +
            '<img alt="Thumbnail" src="' + this.article.asset_thumbnail.asset_path_signed + '">' +
            '</figure>'
        } else {
          this.imgHtml = '<figure class="image is-128x128" style="margin-bottom: 112px;position: unset !important;">' +
            '<img alt="Thumbnail" src="' + this.article.asset_thumbnail.asset_path_signed + '">' +
            '</figure>'
        }
      }
    }
  }
}
</script>
<style scoped>
  #col_3{
    height: 200px;
  }
</style>
