const { CLIENT_ID, ISSUER } = process.env

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: window.location.origin + '/storyfinder/oktasuccess',
    scopes: ['openid', 'profile']
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages'
  },
  publicationWiseIssueLimit: {
    '49e60428570a609066fab5fa4021d3fc': '2004,-1', // All you
    'b496baf7bc768078e47a337087f71ed6': '2002,-1', // Business 2.0
    'b494859f2323f8535dc15df547f2e8df': '2009,-1', // Coastal living
    '208ef5ec7e138fb2e9f40668a9364589': '2010,-1', // Cooking light
    'b06ab434f3ac747210f0a83a77e6ef75': '1990,7', // Entertainment weekly
    'c84a254a82017abff0d5e695cfdca415': '2014,-1', // Food and wine
    '32a2f3e0dee8d733d4209c5752423859': '2004,-1', // Health
    'c8c71147a8e83c2b93b5ff4557028096': '2013,-1', // Homes and Gardens
    'c3edc06c3a4a9e103f4c76a28d08abda': '1994,7', // Instyle
    '2920fa3cc18179e973eeaf1a6fe9728c': '1973,-1', // Life
    'c60782e796be662c6177242f89351537': '1974,2', // People
    '095769961482edee01a241e51a074103': '2002,-1', // People stylewatch
    '84da6d8bcd81b4b245416b4981c3f05a': '1996,-1', // People Enespanol
    '2e1094dd8d4f4f37d4ced9b4b261ed5f': '2000,1', // Realsimple
    '8a4b572bface1505fa5f5e39b9820184': '2010,1', // Southern living
    '1dd937657e78fe5e715dbb8c5a3e1da0': '1969,-1', // Travel + Leisure
    '57d772c4311cdd29de5a86676f189c38': '2019,-1', // All recipes
    'a3c7cb234cac2d6c9d57a1cae0e748a6': '2019,0', // BHG
    'c8d103d9fa7eedc84c4592887699f092': '2017,-1', // Midwest living
    '9d4aa6a9b5cc64b016446e3ca3f6f75e': '2018,-1', // Successful Farming
    '36ee95fdbeb564068a938d5bfefd8531': '2019,-1',
    'cf0328c1334afdab66f5cbb333684c7c': '2019,-1', // Country Gardens
    '9e5a22e4a8aa6141751d9d82732467f1': '2019,-1', // Diabetic living
    '990f5dab30fb2a0cdeaf94a40deb74d0': '2019,-1', // Do it yourself
    '274d76506d92bdcfa2c4d3c2ca255043': '2019,-1', // Eating well
    '69beabfbebedef5336475da7bf07e57e': '2019,-1', // Family circle
    '947dd511206befafcbb58d84d8c28739': '2019,-1', // Magnolia Journal
    '3f5f1985abb8aa9e46f4705e18ecfb5c': '2018,-1', // Living
    'abc48d5cdff37c0cea0aeeba021621a0': '2019,-1', // Parents
    '3434525fc30f00dc8b931f96df8c9b4b': '2017,-1', // Parents Latina
    'c229372360f5f363d8f67e6da4b8d9a3': '2018,-1', // Rachael ray
    '2ca191054a9deb36458977a8881903f8': '2020,-1', // Reveal
    '651852f8d3dce4fb37fbef1f1d66df86': '2019,-1', // Shape
    '5919e2220f81e1e39e51f1b8c6232bbc': '2019,-1', // Traditional home
    '8e67aa9f2e657d3b49a29d4127bea772': '2019,-1', // Fine Cooking
    'd9be71b30159aabef6c56d4b556f0806': '2020,-1' // Millie
  }
}
